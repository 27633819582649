<template>
<div>
  <div class="mobile-fixed-bottom">
    <div class="cart-footer-cart" v-show="!isModalVisible && cartTotalQuantity!=0">
      <div>
        <p 
          class="text-decoration-underline fs14 color-green2"
          @click="openSummaryModal"
        >
          {{ $t('Details') }}
        </p>
        <h3>{{ cartTotalDiscountVATPrice }} ₪</h3>
      </div>
      <button 
        v-if="!minOrderAmountError"
        class="primary-btn bg-orange order-btn"
        @click="createOrder"
      >
        {{ $t('Order') }}
      </button>
      <router-link
        v-else
        to="/products/shaniv/catalogs"
        tag="button"
        exact
        class="
          text-decoration-none
          primary-btn primary-btn_sm
          bg-blue
          m-0
        "
      >
        {{ $t('Add products') }}
      </router-link>
    </div>
    <CartFooterMenu :cartTotalQuantity="cartTotalQuantity"></CartFooterMenu>
  </div>

  <!-- ---------------------mobile summary modal--------------------- -->
  <div class="summary-modal" v-show="isModalVisible">
    <img src="/assets/img/icons/times-light-black.svg" alt="" class="close-btn pointer" @click="closeSummaryModal">
    <h4 class="text-center">{{ $t('Details') }}</h4>
    <div class="summary-container" v-if="cartTotalQuantity">
      <div class="summary-box">
        <h2 class="fs20">{{ $t('Order summary') }}</h2>
        <div class="supplier-container">
          <div
            class="
              credit-limit
              d-flex
              align-items-center
              justify-content-between
            "
          >
            <div>
              <p class="fs14 d-flex justify-content-between">
                {{ $t('Credit line for utilization') }}:
              </p>
                <p class="fs14 d-flex justify-content-between">
                {{ $t('Minimum amount per order is {num}', { num: '' }) }}:
              </p>
            </div>
            <div>
              <p v-if="false" class="fs14 d-flex justify-content-between">
                <b>₪{{ customerBalance }}</b>
              </p>
              <p class="fs14 d-flex justify-content-between">
                <b class="color-red9">₪{{ minOrderAmount }}</b>
              </p>
            </div>
          </div>

          <div class="discount">
            <div class="flex fs14">
              <p>{{ $t('Order value') }}:</p>
              <p class="weight-700">₪{{ cartTotalVATPrice }}</p>
            </div>
            <div v-for="(company_data, company_name) in cart"
              :key="'company_' + company_name"
              class="flex fs14"
            >
              <p>{{ $t('Order value') }} ({{company_name}}):</p>
              <p class="weight-700">₪{{ cartTotalDiscountVATPriceByCompany(company_name) }}</p>
            </div>
            <div
              v-if="cartTotalDiscount && cartTotalDiscount > 0"
              class="flex fs14 color-green3 weight-700"
            >
              <p>{{ $t('Savings') }}:</p>
              <p>₪{{ cartTotalDiscount }}</p>
            </div>
          </div>
          <div class="order-summary fs14">
            <div class="flex">
              <div>
                <p class="mb-2">{{ $t('Order summary') }}:</p>
                <p class="mb-2">{{ $t('Including VAT') }}:</p>
                <p class="mb-2">
                  {{ $t('Agent') }}:
                  <b>אבי דהן</b>
                </p>
                <p class="mb-2">
                  {{ $t('The day the order arrives') }}: <b>{{ dueDate }}</b>
                </p>
              </div>
              <div class="fs18 weight-700">
                <p>₪{{ cartTotalDiscountVATPrice }}</p>
                <p>₪{{ cartTotalDiscountCalcVAT }}</p>
              </div>
            </div>
            <div
              class="
                d-flex
                justify-content-between
                align-items-end
                summary-action
              "
            >
              <p
                class="text-decoration-underline pointer"
                @click="moveCartToTempOrder()"
              >
                {{ $t('Save and complete an order later') }}
              </p>
              <button
                v-if="!minOrderAmountError"
                class="
                  primary-btn primary-btn_sm
                  bg-orange
                  line-height1
                  m-0
                "
                @click="createOrder"
              >
                {{ $t('Make a Reservation') }}
              </button>
              <router-link
                v-else
                to="/products/shaniv/catalogs"
                tag="button"
                exact
                class="
                  text-decoration-none
                  primary-btn primary-btn_sm
                  bg-blue
                  m-0
                "
              >
                {{ $t('Add products') }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import CartFooterMenu from './CartFooterMenu.vue';
export default {
  name: 'MobileFiexedBottom',
  components: {
    CartFooterMenu
  },
  data() {
    return {
      isModalVisible: false
    }
  },
  computed: {
    ...mapState({
      minOrderAmount: (state) => state.shaniv_customer.minOrderAmount,
      customerBalance: (state) => state.shaniv_customer.customerBalance
    }),
    ...mapGetters('cartSystem', {
      cart: 'cart',
      cartTotalDiscountVATPrice: 'cartTotalDiscountVATPrice',
      cartTotalVATPrice: 'cartTotalVATPrice',
      cartTotalDiscountCalcVAT: 'cartTotalDiscountCalcVAT',
      cartTotalQuantity: 'cartTotalQuantity',
      cartTotalDiscount: 'cartTotalDiscount',
      cartTotalDiscountVATPriceByCompany: 'cartTotalDiscountVATPriceByCompany',
      minOrderAmountError: 'minOrderAmountError',
    }),
    dueDate() {
      let dueDate = moment();
      if (dueDate.hour() < 15) {
        dueDate = dueDate.add(1, 'day');
      } else {
        dueDate = dueDate.add(2, 'day');
      }

      return dueDate.format('DD/MM/YYYY dddd');
    },
  },
  methods: {
    ...mapActions('cartSystem', {
      createOrder: 'createOrder',
      clearCart: 'clearCart',
      saveTempOrder: 'saveTempOrder',
    }),
    openSummaryModal() {
      this.isModalVisible = true;
      $("html").scrollTop(0);
      $("html").css("overflow", "hidden");
    },
    closeSummaryModal() {
      this.isModalVisible = false;
      $("html").css("overflow", "auto");
    },
    moveCartToTempOrder() {
      this.saveTempOrder().then((res) => {
        if (res == 'success') {
          this.clearCart();
          this.$router.replace({ name: 'Sastech' });
        }
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.mobile-fixed-bottom {
  display: none;
}
@media (max-width: 480px) {
  .mobile-fixed-bottom {
    display: block;
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    background-color: #fff;
    -webkit-box-shadow: 0px -4px 10px 1px rgba(133,133,133,0.89); 
    box-shadow: 0px -4px 10px 1px rgba(133,133,133,0.89);
    z-index: 11;
    .cart-footer-cart {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 10px;
      .order-btn {
        width: 50%;
        border-radius: 10px;
      }
    }
  }
  .summary-modal {
    position: fixed;
    top: 263px;
    z-index: 10;
    background-color: #fff;
    width: 100%;
    height: calc(100vh - 263px);
    padding: 10px;
    .close-btn {
      position: absolute;
      top: 10px;
      left: 10px;
    }
    .summary-container {
      width: 100%;
      margin-top: 20px;
      .summary-box {
        border: 1px solid #707070;
        border-radius: 6px;
        padding: 10px;

        .supplier-container {
          .flex {
            display: flex;
            align-items: baseline;
            justify-content: space-between;
            margin-bottom: 0.5rem;
          }

          .credit-limit,
          .discount {
            border-bottom: 1px solid #707070;
          }

          .credit-limit,
          .discount,
          .order-summary {
            margin-bottom: 10px;
            padding: 8px 15px;
          }
        }
      }
    }
  }
  .ltr-type {
    @media (max-width: 480px) {
      .summary-modal {
        .close-btn {
          left: unset;
          right: 10px;
        }
      }
    }
  }
}
</style>
