<template>
  <div class="cart-footer-menu">
    <router-link
      :to="{ name: 'Dashboard' }"
    >
      <img src="/assets/img/icons/home-heart-light.svg" alt="">
    </router-link>
    <router-link
      :to="{ name: 'Sastech' }"
    >
      <img src="/assets/img/icons/badge-percent-light.svg" alt="">
    </router-link>
    <router-link
      :to="{ name: 'CartSystem' }"
    >
      <div class="cart-icon-block">
        <img src="/assets/img/icons/shopping-cart-light.svg" alt="">
        <div class="cart-number df-c">
          {{cartTotalQuantity}}
        </div>
      </div>
    </router-link>
    <router-link
      :to="{ name: 'CartOrderHistory' }"
    >
      <img src="/assets/img/icons/file-chart-line-light.svg" alt="">
    </router-link>
    <router-link
      :to="{ name: 'CartTempOrder' }"
    >
      <img src="/assets/img/icons/shopping-cart-light.svg" alt="">
    </router-link>

  </div>
</template>

<script>
export default {
  name: 'CartFooterMenu',
  props: {
    cartTotalQuantity: {
      type: Number,
      default: 0
    }
  }
}
</script>

<style lang="scss" scoped>
  .cart-footer-menu {
    padding: 8px 10px;
    border-top: 1px solid #D6D6D6;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      height: 30px;
    }
    .cart-icon-block {
      position: relative;
      .cart-number {
        position: absolute;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        font-size: 13px;
        background-color: #47E8B7;
        top: -5px;
        right: -10px;

      }
    }
  }
</style>